<template>
  <div class="clientes">
    <h1 class="titulo-card fs-4">Clientes / Visualizar</h1>
    <div class="form-group card-principal">
      <div class="d-flex justify-content-between">
        <div class="input-group mb-3">
          <button v-on:click="$router.go(-1)" class="btn btn-outline-primary">
            <font-awesome-icon :icon="['fas', 'arrow-left']" fixed-width class="me-1"/>Voltar
          </button>
        </div>
        <div class="input-group mb-3 justify-content-end">
          <div class="dropdown open">
            <button class="btn btn-secondary" type="button" id="triggerId" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <font-awesome-icon :icon="['fas', 'bars']" fixed-width/>
            </button>
            <div class="dropdown-menu" aria-labelledby="triggerId">
              <button class="dropdown-item py-2 text-warning" @click="btnCustomerEditHandle()" href="#">
                <font-awesome-icon :icon="['fas', 'pen']" fixed-width/>
                Editar
              </button>
              <hr class="mx-2 my-1">
              <button class="dropdown-item py-2 text-danger" @click="handleRemoveCliente()" href="#">
                <font-awesome-icon :icon="['fas', 'ban']" fixed-width/>
                Desativar o cliente
              </button>
            </div>
          </div>
        </div>
      </div>


      <!-- badges -->
      <div class="form-group">
        <div class="row g-0">
          <div v-if="(customer)" class="col-12 col-sm-6-">
            <h5 class="text-info">
              <font-awesome-icon :icon="['fas', 'user']" fixed-width class="me-1"/>
              <label>{{ customer.name }}</label>
            </h5>
            <p v-if="(customer.obs)" class="col-12 text-warning border rounded border-info p-1">
              <font-awesome-icon :icon="['far', 'comment']" fixed-width class="me-1"/>
              <strong class="me-1">Observação:</strong>
              {{ customer.obs }}
            </p>
          </div>

          <!-- assinaturas -->
          <!-- // TODO: SÓ EXIBIR SE HOUVER ASSINATURAS ATIVAS -->
          <div class="col-12 col-sm-6-">
            <button disabled class="btn btn-success btn-sm- m-1">
              <font-awesome-icon :icon="['fas', 'wrench']" fixed-width class="me-1"/>
              <br class="d-sm-none-">Abrir chamado
            </button>
            <button @click="newSubscription()" class="btn btn-success btn-sm- m-1">
              <font-awesome-icon :icon="['fas', 'plus']" fixed-width class="me-1"/>
              <br class="d-sm-none-">Nova assinatura
            </button>
          </div>
        </div>
      </div>
      <!-- badges -->

      <!-- tabs -->
      <div class="form-group mt-3">
        <nav>
          <div class="nav nav-tabs" id="nav-tab" role="tablist">
            <a class="nav-link active" id="nav-subscriptions-tab" data-bs-toggle="tab" href="#nav-subscriptions" role="tab" aria-controls="nav-subscriptions" aria-selected="true">
              <font-awesome-icon :icon="['fas', 'retweet']" fixed-width/>
              Assinaturas ({{ subscriptions.length }})
            </a>
            <a class="nav-link" id="nav-tickets-tab" data-bs-toggle="tab" href="#nav-tickets" role="tab" aria-controls="nav-tickets" aria-selected="true">
              <font-awesome-icon :icon="['fas', 'wrench']" fixed-width/>
              Chamados ({{ ticketCount }})
            </a>
            <a class="nav-link" id="nav-connections-tab" data-bs-toggle="tab" href="#nav-connections" role="tab" aria-controls="nav-connections" aria-selected="false">
              <font-awesome-icon :icon="['fas', 'network-wired']" fixed-width/>
              Conexões
            </a>
          </div>
        </nav>
        <div class="tab-content" id="nav-tabContent">
          <!-- subscriptions -->
          <div class="tab-pane fade show active px-2" id="nav-subscriptions" role="tabpanel" aria-labelledby="nav-subscriptions-tab">
            <table class="table">
              <thead>
                <tr>
                  <th>Assinaturas</th>
                  <th>Duração</th>
                  <th width="95">Duração</th>
                  <th width="35"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="s in subscriptions" :key="s.id" @click="viewSubscription(s.id)" class="cursor-pointer">
                  <td scope="row" :class="classeListaAssinaturas(s)">
                    {{ s.login }}
                    <font-awesome-icon v-if="(s && s.trustUnlock)" :icon="['fas', 'unlock']" fixed-width class="me-1"/>
                  </td>
                  <td scope="row">
                    -
                  </td>
                  <td>
                    <span class="badge rounded-pill" :class="subscriptionConnectionClass(s)">
                      <font-awesome-icon v-if="(subscriptionIsOnline(s))" :icon="['fas', 'cloud-download-alt']" fixed-width class="me-1"/>
                      {{ subscriptionConnectionBW(s) }}
                    </span>
                  </td>
                  <td>
                    <font-awesome-icon :icon="['fas', 'angle-right']" fixed-width/>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- subscriptions -->

          <!-- tickets -->
          <div class="tab-pane fade show px-2" id="nav-tickets" role="tabpanel" aria-labelledby="nav-tickets-tab">
            <CustomerTickets :customerId="customerId"/>
          </div>
          <!-- tickets -->

          <!-- connections -->
          <div class="tab-pane fade show px-2" id="nav-connections" role="tabpanel" aria-labelledby="nav-tickets-tab">
            <CustomerRecentConnections :customerId="customerId"/>
          </div>
          <!-- connections -->
        </div>
      </div>
      <!-- tabs -->

    </div>
  </div>
</template>

<script>
import api from '@/modules/api'
import dialogo from '@/modules/dialogo'
import loading from '@/modules/loading'
import CustomerTickets from '../components/CustomerTickets.vue'
import CustomerRecentConnections from '../components/CustomerRecentConnections.vue'

export default {
  name: 'CustomerView',
  components: {
    CustomerTickets,
    CustomerRecentConnections
  },
  data() {
    return {
      customerId: undefined,
      customer: undefined,
      ticketCount: 0,
      subscriptions: [],
      sessions: [],
      intervalTicketCount: undefined,
      intervalConnectionInfo: undefined,
    }
  },
  methods: {
    initEditCustomer() {
      this.tituloAcao = 'Editar';

      api.get(`/customers/${this.customerId}`).then(res => {
        loading(false);
        if (!res.data.phones) {
          res.data.phones = [];
        }
        this.customer = res.data;
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    handleRemoveCliente() {
      dialogo.confirmacao(`Confirma desativar o cliente <b>${this.customer.name}</b>?`).then(() => {
        loading(true);
        api.delete(`/customers/${this.customerId}`).then(() => {
          loading(false);
          this.$router.push('/clientes');
        }).catch(error => {
          loading(false);
          dialogo.erroHttp(error);
        });
      });
    },

    refreshTicketCount() {
      api.get(`/tickets?customer_id=${this.customerId}`).then(res => {
        this.ticketCount = res.data.totalItems;
      });
    },
    newSubscription() {
      this.$router.push(`/customers/${this.customer.id}/new-subscription`);
    },
    refreshSubscriptionList() {
      api.get(`/isp/subscriptions?customer_id=${this.customerId}&orderBy[status]=asc`).then(res => {
        for (let i = 0; i < res.data.items.length; i++) {
          res.data.items[i]._sessionStatus = undefined;
        }
        this.subscriptions = res.data.items;
      });
    },
    subscriptionIsOnline(subscription) {
      for (let i = 0; i < this.sessions.length; i++) {
        const session = this.sessions[i];

        if (session.username === subscription.login) {
          return true;
        }
      }

      return false
    },
    subscriptionConnectionClass(subscription) {
      if (!this.subscriptionIsOnline(subscription)) {
        return 'bg-secondary';
      }

      return 'bg-primary';
    },
    subscriptionConnectionBW(subscription) {
      if (!this.subscriptionIsOnline(subscription)) {
        return 'desconectado';
      }

      for (let i = 0; i < this.sessions.length; i++) {
        const session = this.sessions[i];

        if (session.username === subscription.login) {
          return this.formatBytes(this.totalDownload(session) + this.totalUpload(session));
        }
      }
    },
    refreshConnectionInfo() {
      api.get(`/isp/sessions?customer_id=${this.customerId}&orderBy[created_at]=desc&status=active`).then(res => {
        this.sessions = res.data.items;
      });
    },
    viewSubscription(id) {
      this.$router.push(`/assinaturas-isp/${id}/mensalidades`);
    },
    
    statusAssinaturaEnum(assinatura) {
      switch (true) {
        case assinatura.status == 'canceled':
          return assinatura.status;

        case assinatura.late:
          return 'late';

        case assinatura.suspended:
          return 'suspended';

        case assinatura.status == 'active':
          return assinatura.status;

        default:
          return 'unknown';
      }
    },

    statusAssinatura(assinatura) {
      const status = this.statusAssinaturaEnum(assinatura);

      switch (status) {
        case 'canceled': 
          return 'Cancelado';

        case 'active':
          return 'Ativo';

        case 'late':
          return 'Atrasado';

        case 'suspended':
          return 'Suspensão manual';

        default:
          return status;
      }
    },

    classeListaAssinaturas(assinatura) {
      const status = this.statusAssinaturaEnum(assinatura);

      switch (status) {
        case 'canceled': 
          return 'text-muted-2';

        case 'active':
          return 'text-info';

        case 'late':
          return 'text-warning';

        case 'suspended':
          return 'text-danger';

        default:
          return status;
      }
    },

    btnCustomerEditHandle() {
      this.$router.push(`/clientes/${this.customer.id}/editar`);
    },

    totalDownload(session) {
      return (session.inputGigawords * (2 ** 32)) + session.inputOctets;
    },

    totalUpload(session) {
      return (session.outputGigawords * (2 ** 32)) + session.outputOctets;
    },

    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return '0 b';

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['b', 'kb', 'mb', 'gb', 'tb', 'pb', 'eb', 'zb', 'yb'];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    },
  },
  created() {
    this.customerId = this.$route.params.customerID;
    if (!this.customerId) {
      this.$router.push({ path: `/clientes` });
      return;
    }

    this.initEditCustomer();
    this.refreshTicketCount();
    this.refreshSubscriptionList();
    this.refreshConnectionInfo();

    this.intervalTicketCount = setInterval(() => {
      this.refreshTicketCount();
    }, 30000);
    this.intervalConnectionInfo = setInterval(() => {
      this.refreshConnectionInfo();
    }, 30000);
  },
  destroyed() {
    clearInterval(this.intervalTicketCount);
    clearInterval(this.intervalConnectionInfo);
  },
}
</script>
