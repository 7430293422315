<script>
import api from '@/modules/api'
import godate from '@/modules/godate'
import shared from '@/modules/shared'

export default {
  name: "CustomerTickets",
  props: {
    customerId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: true,
      ticketList: [],
      ticketHistoryList: [],
    }
  },
  methods: {
    async refreshTicketList() {
      this.loading = true;

      await api.get(`/tickets?customer_id=${this.customerId}&orderBy[created_at]=desc`).then(res => {
        this.ticketList = res.data.items;
      });

      await api.get(`/tickets/done?customer_id=${this.customerId}&orderBy[created_at]=desc`).then(res => {
        this.ticketHistoryList = res.data.items;
      });

      this.loading = false;
    },

    createdAt(inputDate) {
      return godate.toBrazilDate(inputDate)
    },
    isLate(ticket) {
      if (ticket.status != 'pending') {
        return false;
      }

      const dueDate = shared.godate.toDate(ticket.dueDate);

      return (new Date() > dueDate);
    },
  },
  created() {
    this.refreshTicketList();
  },
};
</script>

<style scoped>
</style>

<template>
  <div class="flex mt-2 card-endereco border-primary">
    <div v-if="loading" class="text-center text-muted">
      <font-awesome-icon :icon="['fas', 'spinner']" spin />
    </div>
    <div class="row g-3">
      <!-- tickets abertos -->
      <div class="col-12">
        <div class="table-responsive">
          <h5 class="mb-0 text-info">Chamados abertos</h5>
          <table class="table table-sm-pc table-striped table-hover">
            <thead>
              <tr>
                <th scope="col" width="85">Data</th>
                <th scope="col">Descrição curta</th>
                <th scope="col" width="80"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="ticket in ticketList" :key="ticket.id" class="">
                <td scope="row">{{ createdAt(ticket.createdAt) }}</td>
                <td>
                  <span class="fw-bold">{{ticket.shortDescription}}</span>
                </td>
                <td>
                  <button type="button" class="btn btn-sm btn-outline-primary float-end">
                    Ver
                    <font-awesome-icon :icon="['fas', 'chevron-right']" fixed-width class="ms-1"/>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- tickets abertos -->

      <!-- histórico tickets -->
      <div class="col-12">
        <div class="table-responsive">
          <h5 class="mb-0 text-info">Histórico recente</h5>
          <table class="table table-sm-pc table-striped table-hover">
            <thead>
              <tr>
                <th scope="col" width="85">Data</th>
                <th scope="col">Descrição curta</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="ticket in ticketHistoryList" :key="ticket.id" class="text-muted">
                <td scope="row">{{ createdAt(ticket.createdAt) }}</td>
                <td>
                  <span class="fw-bold">{{ticket.shortDescription}}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- histórico tickets -->

    </div>
  </div>
</template>
