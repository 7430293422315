<script>
import api from '@/modules/api'
import godate from '@/modules/godate'
import shared from '@/modules/shared'

export default {
  name: "CustomerRecentConnections",
  props: {
    customerId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: true,
      sessionList: [],
      intervalUpdateSessionList: undefined,
    }
  },
  methods: {
    refreshTicketList() {
      this.loading = true;
      api.get(`/isp/sessions?customer_id=${this.customerId}&orderBy[created_at]=desc`).then(res => {
        this.loading = false;
        this.sessionList = res.data.items;
      });
    },

    createdAt(inputDate) {
      return godate.toBrazilDateTime(inputDate)
    },
    isLate(session) {
      if (session.status != 'pending') {
        return false;
      }

      const dueDate = shared.godate.toDate(session.dueDate);

      return (new Date() > dueDate);
    },
  },
  created() {
    this.refreshTicketList();
    this.intervalUpdateSessionList = setInterval(() => {
      this.refreshTicketList();
    }, 30000);
  },
  destroyed() {
    clearInterval(this.intervalUpdateSessionList);
  },
};
</script>

<style scoped>
</style>

<template>
  <div class="flex mt-2 card-endereco border-primary">
    <div v-if="loading" class="text-center text-muted">
      <font-awesome-icon :icon="['fas', 'spinner']" spin />
    </div>
    <div class="row g-3">
      <div class="col-12">
        <div class="table-responsive">
          <table class="table table-sm-pc table-striped table-hover">
            <thead>
              <tr>
                <th scope="col">Conexões recentes</th>
                <th scope="col" width="80"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="session in sessionList" :key="session.id" class="">
                <td scope="row">
                  <p class="mb-0 text-muted">
                    {{ createdAt(session.createdAt) }}
                  </p>
                  <p class="mb-0 fw-bold">{{session.username}}</p>
                </td>
                <td>
                  <span v-if="session.status === 'active'" class="badge rounded-pill bg-primary float-end">
                    Conectado
                  </span>
                  <span v-if="session.status === 'disconnected'" class="badge rounded-pill bg-secondary float-end">
                    Terminado
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
